/**
 * Support for "optional" modules in RequireJS
 *
 * An "optional" module will not kill the entire RequireJS load process (and page script)
 * When the module fails to load, for whatever reason.
 * Consider: ga.js not loading due to ad-blockers.
 *
 * Usage:
 *
 *  require(["optional!moduleName"]), function(moduleName) {});
 *
 * Customized for CourseHorse to also log the failure-to-load to bugsnag
 *
 * More info on what optional does:
 * http://xion.io/post/code/requirejs-optional.html
 *
 */
define("optional", [], {
    load: function (moduleName, parentRequire, onload, config) {
        var onLoadSuccess = function(moduleInstance){
            // Module successfully loaded, call the onload callback so that
            // requirejs can work its internal magic.
            onload(moduleInstance);
        }

        var onLoadFailure = function(err){
            // optional module failed to load.
            var failedId = err.requireModules && err.requireModules[0];
            if (typeof(console) !== "undefined" && console.warn) {
                console.warn("Could not load optional module: " + failedId);

                if (typeof bugsnagClient !== 'undefined' && bugsnagClient.notify) {
                    bugsnagClient.metaData = err;
                    bugsnagClient.notify("Failed to load optional module: " + failedId);
                }
            }

            // Undefine the module to cleanup internal stuff in requireJS
            requirejs.undef(failedId);

            // Now define the module instance as a simple empty object
            // (NOTE: you can return any other value you want here)
            define(failedId, [], function(){return {};});

            // Now require the module make sure that requireJS thinks
            // that is it loaded. Since we've just defined it, requirejs
            // will not attempt to download any more script files and
            // will just call the onLoadSuccess handler immediately
            parentRequire([failedId], onLoadSuccess);
        }

        parentRequire([moduleName], onLoadSuccess, onLoadFailure);
    }
});

